<script setup lang="ts">
interface ITab {
  name: string
  value: string
}

const props = defineProps({
  tabs: {
    type: Array as PropType<ITab[]>,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])

function changeTab(tab: string) {
  emit('update:modelValue', tab)
}
</script>

<template>
  <div class="flex flex-col items-center gap-2">
    <ul class="flex items-center border-b border-primary-95 w-full">
      <li v-for="(tab, i) in props.tabs" :key="i" class="w-full lg:w-fit">
        <button
          type="button"
          aria-label="Botão para trocar de aba"
          :class="
            props.modelValue === tab.value
              ? 'border-b-primary-50 text-primary-50 font-bold'
              : 'border-b-transparent text-primary-20 font-semibold'
          "
          class="lg:px-8 py-3 border-b-2 text-base lg:text-xl transition-all w-full lg:w-fit"
          @click="changeTab(tab.value)"
        >
          {{ tab.name }}
        </button>
      </li>
    </ul>
    <div class="w-full pt-4">
      <slot />
    </div>
  </div>
</template>
